// 1. Include any default functions
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$blue : rgb(87, 36, 194);
$secondary : rgb(182, 41, 212);
$green: rgb(24, 168, 65);
$red: rgb(235, 71, 71);
$yellow : rgb(51, 51, 51);
$light : rgb(26, 169, 178);
$dark : rgb(153, 0, 0);
$cyan : rgb(24, 133, 226);
$white : #ffffff;

@import "../../node_modules/bootstrap/scss/variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";


// 4. Include any optional Bootstrap CSS as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";


// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

@import "../../node_modules/bootstrap/scss/buttons", "../../node_modules/bootstrap/scss/forms", "../../node_modules/bootstrap/scss/tooltip";
textarea {
  resize: none;
  border-radius: 0;
  padding: 10px 10px 10px 15px !important;
}

[class*="material-icons"] {
  font-size: 16px !important;
  width: 15px;
  height: 15px;
}

.flip-icon {
  transform: rotate(180deg);
}

.sticky-options,
.dragicon {
  @media only screen and (min-width: 1023px) {
    visibility: hidden;
    display: none;
  }
}

.dragicon {
  position: absolute;
  left: -12px;
  top: 5px;
  display: none;
}

.sticky-card {
  &:hover {
    .sticky-options {
      visibility: visible;
      display: block;
    }

    .dragicon {
      @media only screen and (min-width: 768px) {
        visibility: visible;
        display: block;
      }
    }
  }

  &-empty {
    border-style: dashed !important;
  }

  .dragHadle {
    cursor: grabbing;
  }

  .color-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    border-radius: 100% 0% 0% 100% / 100% 100% 0% 0%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;

  &-track {
    background: rgba(0, 0, 0, .05);
  }

  &-thumb {
    border-radius: 8px;
    background: rgba(0, 0, 0, .1);

    &:hover {
      background: rgba(0, 0, 0, .11);
    }
  }
}

.sortable- {
  &ghost {
    opacity: .7;
    position: relative;

    &:before {
      position: absolute;
      background-color: rgba(255, 255, 255, .2);
      border: 1px dashed rgba(0, 0, 0, .7);
      content: 'open_with';
      font-size: 70px;
      font-family: 'Material Icons';
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      color: lighten($primary, 70%);
      width: 100%;
      margin-bottom: 0 !important;
      height: 100%;
      border-radius: .25rem;
    }
  }

  &chosen {
    border: 0 !important;
  }
}
.nowrap{
  white-space: nowrap;
}